import React from "react";
import { useState, useEffect } from "react";
import "./initialmint.scss";
// import useEthBalance from "../../hooks/metamaskBal"
import { useWeb3React } from "@web3-react/core";
import CheckBalance from "../../hooks/dataFetchers/balanceOfHydt";
import GetCurrentPrice from "../../hooks/dataFetchers/getCurrentPrice";
import GetDailyMints from "../../hooks/dataFetchers/getDailyMints";
import useWeb3 from "../../hooks/useWeb3";
import ApproveCon from "../../hooks/dataFetchers/approveCon";
import Loader from "../../hooks/loader";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { controlResolverAbi } from "utils/contractHelpers";

const Initialmint = () => {
  const [timeshow, settimeshow] = useState(false);
  const { balanceOfHydt } = CheckBalance();
  const { currentPrice } = GetCurrentPrice();
  const { dailyMints } = GetDailyMints();
  const { buyHydtCon } = ApproveCon();
  // // console.log("difference we get here is ", timeshow)
  const [day, setDay] = useState(0);
  const [hour, setHour] = useState(0);
  const [min, setMin] = useState(0);
  const [sec, setSec] = useState(0);
  const [balance1, setBalance] = useState(0);
  const [spendBnb, setSpendBnb] = useState("");
  const [maxBal, setMaxBal] = useState("");
  const web3 = useWeb3();
  const { account } = useWeb3React();
  const [hydtBalance, setHydtBalance] = useState("");
  const [currentBal, setCurrentBal] = useState("");
  const [mintAmount, setMintAmount] = useState("");
  const [endTime, setEndTime] = useState("");
  const [approvee, setApprovee] = useState("");
  const [mainLoader, setMainLoader] = useState(false);

  function timer() {
    var time = new Date(endTime * 1000);
    var now = new Date();
    var diff = time.getTime() - now.getTime();

    if (diff <= 0) {
      settimeshow(true);
      if (sec && min == 0) {
        getDailyMints();
      }
      return;
    }

    var days = Math.floor(diff / (1000 * 60 * 60 * 24));
    var hours = Math.floor(diff / (1000 * 60 * 60));
    var mins = Math.floor(diff / (1000 * 60));
    var secs = Math.floor(diff / 1000);
    var d = days;
    var h = hours - days * 24;
    var m = mins - hours * 60;
    var s = secs - mins * 60;
    setDay(d);
    setHour(h);
    setMin(m);
    setSec(s);
  }
  // console.log("DDddd", hour, min, sec);

  setInterval(() => {
    timer();
  }, 1000);

  const checkBal = async () => {
    web3.eth.getAccounts(function (err, account) {
      account = account;
    });
    return web3.utils.fromWei(await web3.eth.getBalance(account), "ether");
  };
  // console.log("timeshoowowww", timeshow);
  // useEffect(() => {
  //   if ((min== 0  && sec == 0)) {
  //     // console.log("00000000011111111111");
  //     setTimeout(() => {
  //     // console.log("000000022222222222");
  //       getDailyMints();
  //       // if(endTime >=0){
  //       //   setInterval(() => {
  //       //     timer();
  //       //   }, 1000);
  //       // }
  //     }, 15000);

  //   }
  // }, [min== 0  && sec== 0 ]);

  // console.log("mintAmountmintAmount", mintAmount);
  useEffect(() => {
    if (account) {
      checkBal().then((res) => {
        setBalance(res);
      });
    }
  }, [account]);

  const getBalance = async () => {
    try {
      let result = await balanceOfHydt(account);
      setHydtBalance(result);
    } catch (error) { }
  };
  const CalMintAmount = () => {
    return ((mintAmount / 700000) * 100).toFixed(2);
  };
  const getCurrBalance = async () => {
    try {
      let result = await currentPrice();
      console.log("34343434334", result);
      setCurrentBal(result);
    } catch (error) {
      console.log("34343434334", error);
    }
  };
  // console.log("55555555", approvee);
  
  const getDailyMints = async () => {
    try {
      // console.log("set gggggggggggggggg");
      let result = await dailyMints(account);
      setMintAmount(web3.utils.fromWei(result.amountUSD, "ether"));
      setEndTime(result.endTime);
      // console.log("44444", result.endTime);
    } catch (error) {
      // console.log("222222", error);
    }
  };
  // console.log("set gggggggggggggggg111111", endTime);
  const BuyHydt = async () => {
    // console.log('ettth', balance1, spendBnb, typeof parseFloat(spendBnb), typeof parseFloat(balance1))
    if (parseFloat(spendBnb) > 0) {
      if (parseFloat(spendBnb) <= parseFloat(balance1)) {
        setMainLoader(true);
        try {
          let result = await buyHydtCon(parseFloat(spendBnb).toFixed(18));
          setApprovee(result);
          if (result) {
            getDailyMints();
            setMainLoader(false);
            CalMintAmount();
            setSpendBnb("");
            setMaxBal("");
            getBalance("");
          }
          toast.success("Transaction Successfull", {
            position: "top-right",
            autoClose: 2000,
          });
        }
        catch (error) {
          let a = error.message.slice(29, 47);
          // console.log("aaaaa", error.message.slice(0, 750))
          setMainLoader(false);
          toast.error(a?.includes('transaction') ? 'User Denied Transaction' : a?.includes('price') ? 'Insufficient funds for gas' : a, {
            position: "top-right",
            autoClose: 2000,
          });
        }
      } else {
        toast.info('Amount exceeds current balance!', {
          position: "top-right",
          autoClose: 3000,
        })
      }
    } else {
      toast.info('Input value must be greater than zero!', {
        position: "top-right",
        autoClose: 2000,
      })
    }
  };
  const hanHydt = async (e) => {
    if (currentBal) {
      // console.log("11111", e.target.value, currentBal, e.target.value / currentBal);
      setMaxBal(e.target.value);
      const val = (e.target.value / currentBal);
      let userBalac = await financial(val);
      setSpendBnb(userBalac);
    }
  };
  const hanBnb = async (e) => {
    console.log("e.target.value/currentBal", currentBal)
    if (currentBal) {
      setSpendBnb(e.target.value);
      let userBalac = await financial(e.target.value * currentBal);
      setMaxBal(userBalac);
    }
  };

  const financial = async (bal) => {
    return Number.parseFloat(bal).toFixed(18)
  }
  // let abc = (per * userBal) / 100;
  //       // console.log("abc11111", abc);
  //       let userBalac = await financial(abc);
  useEffect(() => {
    if (account) {
      getBalance();
      getCurrBalance();
      getDailyMints();
    }
  }, [account]);

  console.log("spendBnb", balance1 , currentBal);
  return (
    <>
      {mainLoader && <Loader />}
      <section className="main-initialmint">
        <div className="container-fluid">
          <div className="row">
            <div className="col-xl-12 col-12 p-0">
              <div className="main-head">
                <h3>Mint HYDT Stablecoin</h3>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-4 col-md-12 col-12 pr-0 padd-sm">
              <div className="main-card">
                <div className="minthyd">
                  <h3>Mint HYDT Stablecoin
                  </h3>
                </div>
                <div className="main-parent">
                  <div className="parent">
                    <div className="left">
                      <h4>Spend</h4>
                    </div>
                    <div className="right">
                      <h4>
                        {balance1 != 0 ? parseFloat(balance1)?.toFixed(3) : 0} <span>:Balance</span>
                      </h4>
                    </div>
                  </div>
                  <div className="filedinput">
                    <img
                      src="\Coin_Iconss.svg"
                      alt="img"
                      className="icon-inner-input"
                    />
                    <input
                      type="number"
                      placeholder="0.00"
                      onChange={(e) => hanBnb(e)}
                      value={spendBnb}
                    // min={0}
                    // maxlength ={19}
                    // max={balance1 !=0 && parseFloat(balance1).toFixed(2)}
                    />
                    <div className="buttonmax" onClick={() => { setSpendBnb(balance1); setMaxBal(balance1 * currentBal); }}>
                      <button>MAX</button>
                    </div>
                  </div>
                  <div className="parent">
                    <div className="left">
                      <h4>Receive</h4>
                    </div>
                    <div className="right">
                      <h4>
                        {hydtBalance ? parseFloat(hydtBalance).toFixed(5) : 0} <span>:Balance</span>
                      </h4>
                    </div>
                  </div>
                  <div className="filedinput">
                    <input type="number" placeholder="0.00" value={maxBal} onChange={(e) => hanHydt(e)} />
                    <img
                      src="\Coin_Icons.svg"
                      alt="img"
                      className="img-fluid icon-inner-input"
                    />
                  </div>
                  {/* <div className="parent">
                    <div className="left">
                      <h5>Exchange Rate</h5>
                    </div>
                    <div className="right">
                      <h6>
                        1 <span>HYDT/BNB</span>
                      </h6>
                    </div>

                  </div> */}
                  <div className="parent">
                    <div className="left">
                      <h5>Exchange Rate</h5>
                    </div>
                    <div className="right">
                      <h6>
                        {currentBal ? parseFloat(currentBal).toFixed(2) : 0} <span>HYDT/BNB</span>
                      </h6>
                    </div>

                  </div>
                </div>
                <div className="buttonapprove">
                  <button onClick={BuyHydt}>Mint HYDT</button>
                  <a href="https://pancakeswap.finance/swap?outputCurrency=0x9810512Be701801954449408966c630595D0cD51&inputCurrency=0x55d398326f99059fF775485246999027B3197955" target="_blank">
                  <button style={{marginTop : '0'}} className="brdrbtn">Buy HYDT on Pancakeswap</button>
                  </a>
                </div>
                {/* <div className="img-end">
                  <img src="\elipis5.svg" alt="img" />
                </div> */}
              </div>
            </div>
            <div className="col-xl-8 col-md-12 col-12 padd-sm">
              <div className="cardmain">
                <div className="minlimit">
                  <h3>Daily Mint Limit</h3>
                </div>
                <div className="parentmain">
                  <div className="parent">
                    <div className="left">
                      <h4>Daily HYDT Initial mint allowance</h4>
                    </div>
                    <div className="right">
                      <h4>{mintAmount} / 700000</h4>
                    </div>
                  </div>
                  <div className="main-progress">
                    <div class="progress">
                      <div
                        class="progress-bar"
                        role="progressbar"
                        style={{ width: CalMintAmount() + "%" }}
                        aria-valuenow="25"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      ></div>
                    </div>
                    <div className="heading">
                      <div className="left">
                        {" "}
                        <p>0%</p>
                      </div>
                      <div className="right">
                        <p>100%</p>
                      </div>
                    </div>
                    <div
                      className="progress24"
                      style={{ left: CalMintAmount() + "%" }}
                    >
                      <div className="box"></div>
                      <p>{CalMintAmount() + "%"}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="cardmain2">
                <div className="maincardhead">
                  <p>Daily Mint Limit Restart in</p>
                  <h4>
                    {hour ? hour : 0} : {min ? min : 0} : {sec ? sec : 0}
                  </h4>
                </div>
              </div>
              {/* <div className="cardmain2 iftemporaryclosed">
                <div className="">
                  <img src="\assests\initialmint.svg" alt="img" className="img-fluid" />
                  <h6>Initial Mint function is temporarily <span style={{color: "#C93636"}}>closed</span> until further notice as we are entering upgrade phase. New  Smart contract is planned to be implemented during <span style={{color: "#1777E2"}}>Q1 2024</span>. You can still purchase HYDT Stablecoin on Pancakeswap.</h6>
                  <a href="#" className="buy-swap-btn">Buy on <svg xmlns="http://www.w3.org/2000/svg" width="97" height="15" viewBox="0 0 97 15" fill="none">
                    <path d="M18.6076 11.6118C18.3675 11.6118 18.2474 11.5518 18.1274 11.4918C18.0673 11.3717 18.0073 11.2517 18.0073 11.0716V4.34878C18.0073 4.16871 18.0673 3.98864 18.1274 3.92861C18.2474 3.80856 18.3675 3.74854 18.6076 3.74854H21.4287C22.5092 3.74854 23.2895 3.98864 23.8297 4.40881C24.3099 4.88901 24.6101 5.54928 24.6101 6.44966C24.6101 7.35003 24.37 8.01031 23.8297 8.49051C23.3495 8.91068 22.5092 9.15078 21.4287 9.15078H20.3483V11.0116C20.3483 11.1916 20.2883 11.3717 20.2282 11.4317C20.1082 11.5518 19.9881 11.5518 19.748 11.5518H18.6076V11.6118ZM21.2487 7.47008C21.5488 7.47008 21.7889 7.41006 21.969 7.22998C22.149 7.04991 22.2091 6.80981 22.2091 6.44966C22.2091 6.08951 22.149 5.84941 21.969 5.66933C21.7889 5.48926 21.5488 5.42924 21.2487 5.42924H20.3483V7.47008H21.2487Z" fill="white" />
                    <path d="M27.3112 11.7918C26.5909 11.7918 25.9906 11.5517 25.5704 11.0715C25.1503 10.5913 24.9102 9.93106 24.9102 9.09071C24.9102 8.43044 25.0302 7.89022 25.3303 7.41002C25.6305 6.92982 26.0506 6.56967 26.5909 6.26954C27.1311 6.02944 27.7914 5.84937 28.4516 5.84937C29.0519 5.84937 29.5321 5.90939 30.0123 6.02944C30.4925 6.14949 30.8526 6.32957 31.2728 6.56967V11.1916C31.2728 11.3116 31.2728 11.4317 31.2128 11.4917C31.1528 11.5517 31.0327 11.5517 30.9127 11.5517H29.7122C29.6521 11.5517 29.5321 11.5517 29.5321 11.4917C29.4721 11.4317 29.4721 11.4317 29.412 11.3717L29.232 11.0115C28.9919 11.2516 28.7518 11.4317 28.4516 11.5517C28.1515 11.7318 27.7313 11.7918 27.3112 11.7918ZM28.1515 10.2312C28.4516 10.2312 28.6917 10.1712 28.8718 9.99109C29.0519 9.87104 29.1119 9.63094 29.1119 9.39084V7.59009C28.9919 7.53007 28.7518 7.47004 28.5717 7.47004C28.1515 7.47004 27.8514 7.59009 27.6113 7.89021C27.3712 8.19034 27.2511 8.55049 27.2511 9.09071C27.2511 9.81101 27.5513 10.2312 28.1515 10.2312Z" fill="white" />
                    <path d="M32.8937 11.6115C32.6536 11.6115 32.5336 11.5515 32.4135 11.4915C32.2935 11.3715 32.2935 11.2514 32.2935 11.0713V6.38938C32.2935 6.26933 32.2935 6.14928 32.3535 6.08925C32.4135 6.02923 32.5336 6.02923 32.6536 6.02923H33.8541C33.9742 6.02923 34.0342 6.02923 34.0342 6.08925C34.0942 6.08925 34.0942 6.14928 34.1542 6.2093L34.2143 6.56945C34.4544 6.32935 34.6945 6.2093 35.0546 6.08925C35.4148 5.9692 35.7749 5.90918 36.2551 5.90918C36.8554 5.90918 37.3956 6.08925 37.7557 6.4494C38.1159 6.80955 38.356 7.28975 38.356 8.01005V11.0713C38.356 11.2514 38.2959 11.4315 38.2359 11.4915C38.1759 11.6116 37.9958 11.6115 37.7557 11.6115H36.6153C36.3752 11.6115 36.2551 11.5515 36.1351 11.4915C36.015 11.3715 36.015 11.2514 36.015 11.0713V8.1301C36.015 7.89 35.955 7.70993 35.8349 7.6499C35.7149 7.52985 35.5948 7.46983 35.3547 7.46983C35.1146 7.46983 34.9346 7.52985 34.8145 7.6499C34.6945 7.76995 34.6344 7.95003 34.6344 8.1301V11.0113C34.6344 11.1914 34.5744 11.3715 34.5144 11.4315C34.4544 11.5515 34.2743 11.5515 34.0342 11.5515H32.8937V11.6115Z" fill="white" />
                    <path d="M42.438 11.7916C41.3576 11.7916 40.5172 11.5515 39.917 11.0113C39.3167 10.5311 39.0166 9.8108 39.0166 8.8504C39.0166 8.25015 39.1366 7.76995 39.4368 7.28975C39.6769 6.86958 40.097 6.50943 40.5772 6.26933C41.1175 6.02923 41.7177 5.90918 42.378 5.90918C42.9182 5.90918 43.3384 5.9692 43.6986 6.08925C44.0587 6.2093 44.4188 6.32935 44.6589 6.56945C44.719 6.62948 44.779 6.6895 44.779 6.74953C44.779 6.80955 44.719 6.86958 44.6589 6.98963L44.1787 7.82998C44.1187 7.95003 44.0587 8.01005 43.9987 8.01005C43.9387 8.01005 43.8786 7.95003 43.7586 7.89C43.5785 7.76995 43.3384 7.6499 43.1583 7.58988C42.9783 7.52985 42.7382 7.46983 42.4981 7.46983C42.1379 7.46983 41.7777 7.58988 41.5376 7.82998C41.2975 8.07008 41.1775 8.43023 41.1775 8.8504C41.1775 9.27058 41.2975 9.63073 41.5376 9.87083C41.7777 10.1109 42.0779 10.231 42.4981 10.231C42.7382 10.231 42.9783 10.171 43.1583 10.1109C43.3384 10.0509 43.5785 9.93085 43.7586 9.8108C43.8786 9.75078 43.9387 9.69075 43.9987 9.69075C44.0587 9.69075 44.1187 9.75078 44.1787 9.87083L44.719 10.7712C44.779 10.8312 44.779 10.8913 44.779 10.9513C44.779 11.0113 44.719 11.0713 44.6589 11.1314C44.3588 11.3114 43.9987 11.4915 43.5785 11.6115C43.3984 11.7316 42.9782 11.7916 42.438 11.7916Z" fill="white" />
                    <path d="M47.8995 11.7918C47.1792 11.7918 46.579 11.5517 46.1588 11.0715C45.7386 10.5913 45.4985 9.93106 45.4985 9.09071C45.4985 8.43044 45.6186 7.89022 45.9187 7.41002C46.2188 6.92982 46.639 6.56967 47.1792 6.26954C47.7195 6.02944 48.3797 5.84937 49.04 5.84937C49.6403 5.84937 50.1205 5.90939 50.6007 6.02944C51.0809 6.14949 51.441 6.32957 51.8612 6.56967V11.1916C51.8612 11.3116 51.8612 11.4317 51.8012 11.4917C51.7411 11.5517 51.6211 11.5517 51.501 11.5517H50.3005C50.2405 11.5517 50.1205 11.5517 50.1205 11.4917C50.0604 11.4317 50.0604 11.4317 50.0004 11.3717L49.8203 11.0115C49.5802 11.2516 49.3401 11.4317 49.04 11.5517C48.7399 11.7318 48.3797 11.7918 47.8995 11.7918ZM48.7999 10.2312C49.1 10.2312 49.3401 10.1712 49.5202 9.99109C49.7003 9.87104 49.7603 9.63094 49.7603 9.39084V7.59009C49.6403 7.53007 49.4002 7.47004 49.2201 7.47004C48.7999 7.47004 48.4998 7.59009 48.2597 7.89021C48.0196 8.19034 47.8995 8.55049 47.8995 9.09071C47.8395 9.81101 48.1396 10.2312 48.7999 10.2312Z" fill="white" />
                    <path d="M59.1849 11.1916C59.245 11.2517 59.245 11.3117 59.245 11.3717C59.245 11.4317 59.245 11.4918 59.1849 11.5518C59.1249 11.6118 59.0649 11.6118 59.0049 11.6118H57.2041C57.0841 11.6118 57.024 11.6118 56.964 11.6118C56.904 11.6118 56.844 11.5518 56.7839 11.4918L55.2233 9.33086V11.0116C55.2233 11.1916 55.1633 11.3717 55.1033 11.4317C54.9832 11.5518 54.8631 11.5518 54.623 11.5518H53.4826C53.2425 11.5518 53.1224 11.4918 53.0024 11.4317C52.9424 11.3117 52.8823 11.1916 52.8823 11.0116V4.34878C52.8823 4.16871 52.9424 3.98864 53.0024 3.92861C53.1224 3.80856 53.2425 3.74854 53.4826 3.74854H54.623C54.8631 3.74854 54.9832 3.80856 55.1033 3.92861C55.2233 4.04866 55.2233 4.16871 55.2233 4.34878V8.19038L56.7239 6.20956C56.7839 6.14953 56.844 6.08951 56.904 6.08951C56.964 6.08951 57.024 6.02949 57.1441 6.02949H58.9448C59.0049 6.02949 59.0649 6.02948 59.1249 6.08951C59.1849 6.14953 59.1849 6.20956 59.1849 6.26958C59.1849 6.32961 59.1849 6.38963 59.1249 6.44966L57.1441 8.73061L59.1849 11.1916Z" fill="white" />
                    <path d="M62.9063 11.7917C62.246 11.7917 61.7058 11.6716 61.1656 11.4915C60.6854 11.2514 60.2652 10.9513 59.9651 10.5311C59.665 10.111 59.5449 9.57074 59.5449 8.97049C59.5449 8.01009 59.845 7.22976 60.3853 6.74956C60.9255 6.20934 61.7658 5.96924 62.7863 5.96924C63.8067 5.96924 64.587 6.20934 65.0672 6.74956C65.6074 7.22976 65.8475 7.95006 65.8475 8.85044C65.8475 9.21059 65.6675 9.45069 65.3673 9.45069H61.7658C61.7658 9.81084 61.8859 10.0509 62.126 10.231C62.3661 10.4111 62.7863 10.4711 63.2665 10.4711C63.5666 10.4711 63.8667 10.4711 64.0468 10.4111C64.2869 10.3511 64.467 10.231 64.7071 10.171C64.8271 10.111 64.8871 10.111 64.8871 10.111C64.9472 10.111 65.0072 10.171 65.0672 10.231L65.4874 10.9513C65.5474 11.0113 65.5474 11.0714 65.5474 11.1314C65.5474 11.1914 65.4874 11.2514 65.4274 11.3115C65.1272 11.4915 64.7671 11.6716 64.3469 11.7917C63.8667 11.7316 63.4465 11.7917 62.9063 11.7917ZM63.8067 8.19016C63.8067 7.89004 63.7467 7.64994 63.5666 7.52989C63.3865 7.34981 63.1464 7.28979 62.7863 7.28979C62.4261 7.28979 62.186 7.34981 62.0059 7.52989C61.8259 7.70996 61.7658 7.89004 61.7658 8.19016H63.8067Z" fill="white" />
                    <path d="M69.8692 11.7918C69.2089 11.7918 68.6087 11.7318 68.0084 11.6118C67.4082 11.4917 66.988 11.2516 66.6278 11.0115C66.5078 10.9515 66.4478 10.8314 66.4478 10.7714C66.4478 10.7114 66.4478 10.6514 66.5078 10.5913L67.048 9.69097C67.108 9.57092 67.1681 9.57092 67.2281 9.57092C67.2881 9.57092 67.3481 9.57092 67.4082 9.63095C67.7083 9.81102 68.0084 9.93107 68.3686 10.0511C68.7287 10.1712 69.0889 10.2312 69.449 10.2312C69.8092 10.2312 70.0493 10.1712 70.2293 10.0511C70.4094 9.93107 70.4694 9.751 70.4694 9.5109C70.4694 9.2708 70.3494 9.09072 70.1693 8.97067C69.9892 8.85062 69.6291 8.67055 69.0889 8.49047C68.3085 8.19035 67.7083 7.89022 67.2881 7.53007C66.8679 7.16992 66.6278 6.6297 66.6278 6.02945C66.6278 5.24913 66.928 4.7089 67.4682 4.28873C68.0084 3.86855 68.7287 3.68848 69.6291 3.68848C70.2894 3.68848 70.8296 3.7485 71.2498 3.86855C71.73 3.9886 72.0901 4.16868 72.3902 4.34875C72.5103 4.4688 72.5703 4.52883 72.5703 4.64887C72.5703 4.7089 72.5703 4.76893 72.5103 4.82895L71.97 5.72933C71.91 5.84938 71.85 5.84937 71.79 5.84937C71.73 5.84937 71.6699 5.84938 71.6099 5.78935C71.1897 5.48923 70.6495 5.36917 69.9892 5.36917C69.6291 5.36917 69.389 5.4292 69.2089 5.54925C69.0288 5.6693 68.9688 5.84938 68.9688 6.08947C68.9688 6.26955 69.0288 6.3896 69.0889 6.50965C69.2089 6.6297 69.329 6.68972 69.449 6.80977C69.6291 6.8698 69.8692 6.98985 70.1693 7.1099L70.4094 7.16992C70.9496 7.41002 71.4298 7.5901 71.73 7.8302C72.0901 8.01027 72.3302 8.3104 72.5103 8.61052C72.6904 8.91065 72.7504 9.2708 72.7504 9.69097C72.7504 10.3512 72.5103 10.9515 71.97 11.3116C71.6099 11.5517 70.8296 11.7918 69.8692 11.7918Z" fill="white" />
                    <path d="M75.3315 11.6116C75.2114 11.6116 75.1514 11.6116 75.0914 11.5516C75.0314 11.4916 74.9713 11.4315 74.9713 11.3115L73.4707 6.32942C73.4707 6.2694 73.4707 6.2694 73.4707 6.20937C73.4707 6.08932 73.5307 6.0293 73.7108 6.0293H75.2114C75.3315 6.0293 75.3915 6.0293 75.4515 6.08932C75.5116 6.14935 75.5116 6.14935 75.5716 6.2694L76.1718 8.91049L76.9522 6.80962C77.0122 6.68957 77.0122 6.62955 77.0722 6.62955C77.1322 6.56952 77.1923 6.56952 77.3123 6.56952H78.0326C78.1527 6.56952 78.2127 6.56952 78.2727 6.62955C78.3327 6.68957 78.3327 6.7496 78.3928 6.80962L79.1731 8.91049L79.8334 6.2694C79.8334 6.20937 79.8934 6.14935 79.9534 6.08932C80.0134 6.0293 80.0735 6.0293 80.1335 6.0293H81.6941C81.8742 6.0293 81.9342 6.08932 81.9342 6.20937C81.9342 6.20937 81.9342 6.2694 81.9342 6.32942L80.4336 11.3115C80.3736 11.4315 80.3736 11.4916 80.3136 11.5516C80.2535 11.6116 80.1935 11.6116 80.0734 11.6116H78.933C78.8129 11.6116 78.7529 11.6116 78.6929 11.5516C78.6329 11.4916 78.5728 11.4315 78.5728 11.3115L77.7925 9.21062L77.0122 11.3115C76.9522 11.4315 76.9522 11.4916 76.8921 11.5516C76.8321 11.6116 76.7721 11.6116 76.652 11.6116H75.3315Z" fill="white" />
                    <path d="M84.6954 11.7918C83.9751 11.7918 83.3749 11.5517 82.9547 11.0715C82.5345 10.5913 82.2944 9.93106 82.2944 9.09071C82.2944 8.43044 82.4145 7.89022 82.7146 7.41002C83.0147 6.92982 83.4349 6.56967 83.9751 6.26954C84.5153 6.02944 85.1756 5.84937 85.8359 5.84937C86.4362 5.84937 86.9164 5.90939 87.3966 6.02944C87.8768 6.14949 88.2369 6.32957 88.6571 6.56967V11.1916C88.6571 11.3116 88.6571 11.4317 88.5971 11.4917C88.537 11.5517 88.417 11.5517 88.2969 11.5517H87.0964C87.0364 11.5517 86.9764 11.5517 86.9164 11.4917C86.8563 11.4317 86.8563 11.4317 86.7963 11.3717L86.6162 11.0115C86.3761 11.2516 86.136 11.4317 85.8359 11.5517C85.5358 11.7318 85.1756 11.7918 84.6954 11.7918ZM85.5958 10.2312C85.8959 10.2312 86.136 10.1712 86.3161 9.99109C86.4962 9.87104 86.5562 9.63094 86.5562 9.39084V7.59009C86.4362 7.53007 86.1961 7.47004 86.016 7.47004C85.5958 7.47004 85.2957 7.59009 85.0556 7.89021C84.8155 8.19034 84.6954 8.55049 84.6954 9.09071C84.6354 9.81101 84.9355 10.2312 85.5958 10.2312Z" fill="white" />
                    <path d="M90.2775 13.9526C90.0374 13.9526 89.9174 13.8925 89.7973 13.8325C89.7373 13.7125 89.6772 13.5924 89.6772 13.4123V6.80959C90.0374 6.56949 90.4576 6.32939 90.9978 6.20934C91.538 6.02926 92.0783 5.96924 92.6185 5.96924C94.9594 5.96924 96.0999 6.92964 96.0999 8.91046C96.0999 9.81084 95.8598 10.5311 95.3796 11.0714C94.8994 11.6116 94.1791 11.8517 93.2787 11.8517C93.0386 11.8517 92.7985 11.7917 92.5584 11.7316C92.3183 11.6716 92.1383 11.6116 91.9582 11.4915V13.4123C91.9582 13.5924 91.8982 13.7725 91.8381 13.8325C91.7181 13.9526 91.598 13.9526 91.3579 13.9526H90.2775ZM92.7986 10.231C93.1587 10.231 93.3988 10.111 93.5188 9.87086C93.6989 9.63076 93.7589 9.27061 93.7589 8.85044C93.7589 8.37024 93.6389 8.01009 93.4588 7.76999C93.2787 7.58991 92.9786 7.46986 92.5584 7.46986C92.3183 7.46986 92.0782 7.52989 91.9582 7.58991V9.39066C91.9582 9.63076 92.0182 9.87086 92.1983 9.99091C92.3184 10.171 92.5585 10.231 92.7986 10.231Z" fill="white" />
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M2.70112 2.42789C2.46102 1.22739 3.42142 0.0869141 4.62192 0.0869141C5.70237 0.0869141 6.60275 0.987288 6.60275 2.06774V4.46874C6.90287 4.46874 7.14297 4.46874 7.4431 4.46874C7.6832 4.46874 7.98332 4.46874 8.22342 4.46874V2.06774C8.22342 0.987288 9.12379 0.0869141 10.2042 0.0869141C11.4047 0.0869141 12.3651 1.22739 12.125 2.42789L11.5848 5.24906C13.3856 6.02938 14.7661 7.40996 14.7661 9.15068V10.1711C14.7661 11.6117 13.8057 12.8122 12.4852 13.5925C11.1646 14.4329 9.36389 14.9131 7.38307 14.9131C5.40225 14.9131 3.6015 14.4329 2.28095 13.5925C0.960399 12.8122 0 11.6117 0 10.1711V9.15068C0 7.40996 1.38057 6.02938 3.18132 5.24906L2.70112 2.42789ZM10.9245 5.66924L11.5248 2.36786C11.7049 1.52751 11.0446 0.747188 10.2042 0.747188C9.42392 0.747188 8.82367 1.34744 8.82367 2.12776V5.18904C8.6436 5.18904 8.4035 5.12901 8.22342 5.12901C7.98332 5.12901 7.6832 5.12901 7.4431 5.12901C7.14297 5.12901 6.90287 5.12901 6.60275 5.18904C6.42267 5.18904 6.18257 5.24906 6.0025 5.24906V2.18779C6.0025 1.40746 5.40225 0.807214 4.62192 0.807214C3.78157 0.807214 3.1213 1.58754 3.30137 2.42789L3.90162 5.78929C1.98082 6.50959 0.660275 7.83014 0.660275 9.33076V10.3512C0.660275 12.6321 3.66152 14.4929 7.38307 14.4929C11.1046 14.4929 14.1059 12.6321 14.1059 10.3512V9.33076C14.1659 7.71009 12.8453 6.38953 10.9245 5.66924Z" fill="#633001" />
                    <path d="M14.1663 10.2311C14.1663 12.5121 11.165 14.3728 7.4435 14.3728C3.72195 14.3728 0.720703 12.5121 0.720703 10.2311V9.21069H14.2263V10.2311H14.1663Z" fill="#FEDC90" />
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M3.30132 2.30793C3.12125 1.46758 3.78152 0.687256 4.62187 0.687256C5.4022 0.687256 6.00245 1.28751 6.00245 2.06783V5.18913C6.48265 5.1291 6.96284 5.06908 7.44304 5.06908C7.92324 5.06908 8.40344 5.1291 8.82362 5.1291V2.06783C8.82362 1.28751 9.42387 0.687256 10.2042 0.687256C11.0445 0.687256 11.7048 1.46758 11.5247 2.30793L10.9245 5.6093C12.9053 6.3296 14.2259 7.65015 14.2259 9.15077C14.2259 11.4317 11.2246 13.2925 7.50307 13.2925C3.78152 13.2925 0.780273 11.4317 0.780273 9.15077C0.780273 7.65015 2.10082 6.3296 4.02162 5.6093L3.30132 2.30793Z" fill="#D1884F" />
                    <path d="M5.5822 8.91049C5.5822 9.51074 5.22205 10.051 4.8619 10.051C4.50175 10.051 4.1416 9.57077 4.1416 8.91049C4.1416 8.31025 4.50175 7.77002 4.8619 7.77002C5.22205 7.77002 5.5822 8.25022 5.5822 8.91049Z" fill="#633001" />
                    <path d="M10.7443 8.91049C10.7443 9.51074 10.3842 10.051 10.024 10.051C9.60384 10.051 9.30371 9.57077 9.30371 8.91049C9.30371 8.31025 9.66386 7.77002 10.024 7.77002C10.3842 7.77002 10.7443 8.25022 10.7443 8.91049Z" fill="#633001" />
                  </svg> </a>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Initialmint;