import { useCallback } from "react";
import { useWeb3React } from "@web3-react/core";
import useWeb3 from "../useWeb3"
import { initialMintV2Contract, proxyControlResolverContract } from "../../utils/contractHelpers";
import Environment from "../../utils/environment";

export const DepositRewardsD2 =()=>{
    const {account} = useWeb3React();
    const web3 = useWeb3();
    const contractAddress = Environment.intialMintV2;
    const hydtPriceContract = proxyControlResolverContract(Environment?.ControlResolverAbi, web3);
    const contract = initialMintV2Contract(contractAddress,web3);
    const dashdepositd2 = useCallback (
        async() =>{
            try {
                const balance = await hydtPriceContract.methods.getLatestPrice().call()
                console.log(web3.utils.fromWei(balance, "ether"),'ttttttttttttt');
                
                return web3.utils.fromWei(balance, "ether");
            } catch (error) {
                console.log('ttttttttttttt', error)
                throw error;
            }
        },[contract]
    );
    return { dashdepositd2: dashdepositd2 };
}
export default DepositRewardsD2;
