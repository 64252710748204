import { useCallback } from "react";
import { useWeb3React } from "@web3-react/core";
import useWeb3 from "../useWeb3"
import { earnAbi, getPancakeHygtHydtContract, initialMintV2Contract, proxyControlResolverContract } from "../../utils/contractHelpers";
import Environment from "../../utils/environment";

export const EarnHygtApy = () => {
    const { account } = useWeb3React();
    const web3 = useWeb3();
    const contractAddress = Environment.EarnAbi;
    const controlAddress = Environment.intialMintV2;
    const pancakeHygtAddress = Environment.pancakeHygtHydt;
    const earncontract = earnAbi(contractAddress, web3);
    const pancakeHygtcontract = getPancakeHygtHydtContract(pancakeHygtAddress, web3);
    const controlcontract = initialMintV2Contract(controlAddress, web3);
    const hydtPriceContract = proxyControlResolverContract(Environment?.ControlResolverAbi, web3);
    const hygtapyAmount = useCallback(
        async () => {
            // let val = web3.utils.toWei(spendBnb.toString(), "ether");
            try {
                const hydtPrice = await hydtPriceContract.methods.getLatestPrice().call();
                // console.log('hydtPrice: ', hydtPrice)
                const hygtPairReserves = await pancakeHygtcontract.methods.getReserves().call();
                // console.log('hygtPairReserves: ', hygtPairReserves)
                const hygtPrice = (hydtPrice * hygtPairReserves._reserve1) / hygtPairReserves._reserve0;
                // console.log('c', hydtPrice, hygtPairReserves._reserve1, hygtPairReserves._reserve0)
                const hygtPerSecond = await earncontract.methods.HYGTPerSecond().call();
                // console.log('hygtPerSecond: ', hygtPerSecond)
                const secondsPerYear = 86400 * 365;
                // console.log('consoleall', hygtPerSecond, secondsPerYear, hygtPrice)
                const hygtPerYearUSD = hygtPerSecond * secondsPerYear * hygtPrice;
                const totalAllocPoint = await earncontract.methods.totalAllocPoint().call();
                // console.log('totalAllocPoint: ', totalAllocPoint)
                const poolLength = await earncontract.methods.poolLength().call();
                // console.log('poolLength: ', poolLength)
                let lockApyArr = []
                for (let i = 0; i < poolLength; i++) {
                    const poolInfo = await earncontract.methods.poolInfo(i).call();
                    // console.log('poolInfo: ', poolInfo, typeof i)
                    const allocPoint = poolInfo.allocPoint;
                    if (allocPoint > 0) {
                        const totalDeposit = poolInfo.stakeSupply; // convert from wei to eth
                        const tvlUsd = totalDeposit * hydtPrice;
                        // console.log('consoleall', allocPoint, totalAllocPoint, hygtPerYearUSD, tvlUsd)
                        if (totalAllocPoint === 0 || tvlUsd === 0) {
                            console.error("Division by zero is not allowed.");
                        } else if (isNaN(allocPoint) || isNaN(totalAllocPoint) || isNaN(hygtPerYearUSD) || isNaN(tvlUsd)) {
                            console.error("One of the variables is NaN.");
                        } else {
                            const APY = ((allocPoint / totalAllocPoint) * hygtPerYearUSD) / tvlUsd * 100;
                            lockApyArr.push(APY)
                            // console.log("c", APY);
                        }
                    }
                }
                return lockApyArr
            } catch (error) {
                // console.log('9999999', error)
                throw error;
            }
        }, [earncontract]
    );
    return { hygtapyAmount: hygtapyAmount };
}
export default EarnHygtApy;

