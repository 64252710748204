import { useCallback } from "react";
import { useWeb3React } from "@web3-react/core";
import useWeb3 from "../useWeb3"
import { reservesCont } from "../../utils/contractHelpers";
import { proxyControlResolverContract } from "../../utils/contractHelpers";
import { priceFeedContract } from "../../utils/contractHelpers";
import { quoteCont } from "../../utils/contractHelpers";
import Environment from "../../utils/environment";

export const GetCurrentPrice = () => {
    const { account } = useWeb3React();
    const web3 = useWeb3();
    const reservesAddress = Environment.reservesContract;
    const quoteAddress = Environment.quoteContract;
    // console.log("reservesAddress", reservesAddress, quoteAddress);
    const hydtPriceContract = proxyControlResolverContract(Environment?.ControlResolverAbi, web3);
    const priceFeed_Contract = priceFeedContract(Environment?.priceFeed, web3);
    const contract = reservesCont(reservesAddress, web3);
    const contract1 = quoteCont(quoteAddress, web3);
    const currentPrice = useCallback(
        async () => {
            try {
                // const cost = await hydtPriceContract.methods.getLatestPrice().call();
                const decimals = await priceFeed_Contract.methods.decimals().call();
                let price = await priceFeed_Contract.methods.latestRoundData().call();
                price = price?.answer
                let totalPrice = parseInt(price) / 10 ** decimals  //aaafffff 999999 8 60000000000 1.6666649999999998e+21
                console.log("aaafffff", decimals, price, totalPrice);
                return totalPrice;
                // }
            } catch (error) {
                console.log('444444444', error)
                throw error;
            }
        }, [contract]
    );
    return { currentPrice: currentPrice };
}
export default GetCurrentPrice;
