let Environment = {
  ControlAbi: '0x9387E4ee95B7904A81739367d435f968780Eb762',
  intialMintV2: '0xD9E190a8BF3E30B69CF8614dA79E8BD37d9C7274',
  FarmAbi: "0x234CBEE2707D84EbC5dC93F5d8e328E458Cf3B9D",
  ReserveAbi: "0x69Fcc8B2fF6922eDE3Baab9A168E8CF6e021CAd7",

  HydtAbi: "0x479F70c2A48134AA44154E0F648f07De154918d4",
  HygtAbi: "0x144c15676425a5192055a15471Da7FC413fa787D",
  ShydtAbi: "0xb36b5716CC186Ae16696De2953ae56DfaFCC23c4",
  priceFeed: '0x9DA7c18BE6fc5864D5C4EBBF51f0ad17ff46D297',
  ControlResolverAbi: "0x2d58B8D6d6FE66678FaD437Ab633728134AB8F30",

  base_url: "https://liveapi.opencanvas.app/v1",

  reservesContract: "0x13E62318FF1C68a83070FB32CB1937C9dbF46cDc", //CoinPair BNB-USDT Pair
  quoteContract: "0x01a93b7153Ee160F3176af0B0F31121DF9f0FFA5", //UniswapV2Router02 same

  routerContract: "0x01a93b7153Ee160F3176af0B0F31121DF9f0FFA5", // UniswapV2Router02 same
  hydtGraph:
    "https://subgraph.satsuma-prod.com/323ce71d2cc0/usman--186639/hydt-protocol-staging/api",
  claim: "0xb198bb68D01F544518e825cAAE360F5a5A9e3e2E", // claim contract from afaq//////// Affiliate Withdraw contract with updated HYDT and HYGT addresses
  pancakeHygtHydt: "0xc0831289219DAE18e8e786ec90dEe0d806D28209", //HYDT-BNB
  // apiUrl: "http://ec2-18-236-118-29.us-west-2.compute.amazonaws.com:5555/",
  // apiUrl: "http://ec2-18-236-118-29.us-west-2.compute.amazonaws.com:5555/",
  apiUrl: "https://hydtauditedapi.quecko.com/",
};

export default Environment;